/*
Home component of Robert Los Commercial and Contract Services BV
(c) 2020 Robert Los Commercial and Contract Services BV

we show the logo and some text
*/
<template>
  <h3>About Robert Los Commercial and Contract Services</h3>
  <p>
    This is the home page of Robert Los Commercial and Contract Services BV.
    <br />
    Robert Los Commercial and Contract Services was first registered at the
    chambers of commerce in Rotterdam in 2012.
    <br />
    We use this site to showcase our work and to provide information about us.
    <br />
    <!-- Inline the image directly into the same text flow -->

    The site is still under construction. We develops it in our spare time
    using Django and Vue-3. Therefore you will find some self developed
    components, like the drawing of Robert here below.
  </p>
  <p>
    We hope to extend this site soon with some more information. On the expertise,
    projects and participations pages you might get an impression of our abilities.
  </p>
  <RlccsImage
      class="floated-image"
      v-model:crossedOut="isCrossed"
      src="/images/robert-jenk-trans.png"
      alt="Himself"
      imgWidth="250px"
      imgHeight="250px"
  />
</template>


<script>
import { defineComponent} from "vue";
import RlccsImage from './RlccsImage.vue'

export default {
  name: 'HomeVue',
  components: {
    RlccsImage
  },
  data() {
    return {
      isCrossed: false,
    };
  },
  methods: {
    toggleCrossed() {
      this.isCrossed = !this.isCrossed;
    },
  },
}
</script>

<style>
/* Style the floated image */
.floated-image img { /* Apply to the <img> inside your RlccsImage component */
  float: left; /* Float the image to the left */
  margin: 0 15px 15px 0; /* Add margin to give space around it */
  display: inline-block; /* Ensure proper display */
  max-width: 100%; /* Make sure it obeys its container size */
  height: auto; /* Maintain aspect ratio */
}
</style>
