<template>
  <div>
    <h3>Projects</h3>
    <p>Find below all projects of Robert Los Commercial and Contract Services</p>
  </div>
  <div class="table-container">
  <table class="styled-table">
    <thead>
    <tr>
      <th>Year</th>
      <th>Client</th>
      <th>Project</th>
      <th>Comment</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="project in projects" :key="project.id">
      <td class="left">{{ formatDate(project.year) }}</td>
      <td class="left">{{ project.client }}</td>
      <td class="left">{{ project.title }}</td>
      <td class="left">{{ project.description }}
      </td>
    </tr>
    </tbody>
  </table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      projects: [], // Stores fetched participation data
    };
  },
  methods: {
    async fetchProjects() {
      try {
        // Fetching participation data from the backend endpoint
        const response = await fetch("http://localhost:8993/references/api/v1/project?format=json");
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        this.projects = data; // Assign the fetched data
      } catch (error) {
        console.error("Failed to fetch projects:", error);
      }
    },
    formatDate(date) {
      // Formats date strings into a more readable format
      const options = { year: "numeric", month: "short", day: "numeric" };
      return new Date(date).toLocaleDateString(undefined, options);
    },
  },
  mounted() {
    // Fetching data as soon as the component mounts
    this.fetchProjects();
  },
};
</script>

<style scoped>
/* Flexbox container to center the table */
.table-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0; /* Remove default margin */
  padding: 10px 0; /* Adjust padding as needed */

  background-color: #f8f9fa; /* Optional subtle background color */
}

/* Styled table */
.styled-table {
  border-collapse: collapse;
  font-size: 16px;
  width: 80%; /* Adjust width as needed */
  max-width: 800px; /* Optional to limit table width */
  margin: 20px 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for aesthetics */
  background-color: #ffffff; /* Background color */
}

/* Table header styling */
.styled-table thead tr {
  background-color: #007bff; /* Header background (blue) */
  color: #ffffff; /* Header text color */
  text-align: left;
}

/* Table cell styles */
.styled-table th,
.styled-table td {
  padding: 12px 15px; /* Add padding to cells */
  border: 1px solid #dddddd; /* Light border around cells */
}

/* Table row hover effect */
.styled-table tbody tr:hover {
  background-color: #f1f1f1; /* Light gray on hover */
}

/* Optional: Style even rows differently */
.styled-table tbody tr:nth-of-type(even) {
  background-color: #f9f9f9; /* Light background for even rows */
}

/* Responsive table for smaller screens */
@media screen and (max-width: 600px) {
  .styled-table {
    font-size: 14px;
    width: 100%; /* Expand table to fit smaller screens */
  }

  .styled-table th,
  .styled-table td {
    padding: 10px; /* Reduce cell padding for smaller screens */
  }
}

</style>
