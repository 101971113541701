<template>
  <div>
    <h3>Contact</h3>
    <p>Robert Los Commercial &amp; Contract Services BV</p>
    <p>
      Nachtegaallaan 46<br />
      3055 CS Rotterdam<br />
      The Netherlands<br />
    </p>
    <p>
      Telephone: +31 (0) 6 24893872<br />
      mail: <a href="mailto:robert@rlccs.nl">robert@rlccs.nl</a>
    </p>
  </div>
</template>

<script>
</script>

<style></style>
