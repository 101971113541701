<template>
  <div class="expertise-container">
    <div>
      <h2 class="section-title">Our Expertise</h2>
      <p>Below is the expertise of Robert Los Commercial and Contract Services</p>
    </div>

    <div class="expertise-cards">
      <div
          v-for="group in expertiseGroups"
          :key="group.id"
          class="expertise-card"
      >
        <h3 class="card-title left">{{ group.name }}</h3>
        <p class="left">{{ group.description }}</p>

        <ul class="card-list">
          <li
              v-for="expertise in group.expertises"
              :key="expertise.id"
              class="left"
              @mouseover="activeExpertiseId = expertise.id"
              @mouseleave="activeExpertiseId = null"
          >
            <span>{{ expertise.name }}</span>

            <!-- Show/hide description based on activeExpertiseId -->
            <transition name="fade">
              <div v-if="activeExpertiseId === expertise.id" class="description-box">
                {{ expertise.description }}
              </div>
            </transition>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExpertiseContainer",
  data() {
    return {
      expertiseGroups: [], // Expertise groups fetched from API
      activeExpertiseId: null, // Tracks the currently focused/hovered expertise
    };
  },
  methods: {
    async fetchExpertise() {
      try {
        const response = await fetch("http://localhost:8993/expertise/api/v1?format=json");
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        this.expertiseGroups = data;
      } catch (error) {
        console.error("Failed to fetch expertise:", error);
      }
    },
  },
  mounted() {
    this.fetchExpertise();
  },
};
</script>

<style scoped>
/* Example styling (customize as needed) */
.expertise-container {
  margin: 1rem auto;
  max-width: 800px;
}

.expertise-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.expertise-card {
  border: 1px solid #ccc;
  padding: 1rem;
  width: 100%;
  max-width: 350px;
}

.card-list li {
  margin-bottom: 0.5rem;
  list-style: none;
  position: relative;
}

.description-box {
  margin-top: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  position: absolute;
  z-index: 100;
  width: calc(100% - 1rem);
  animation: fadeIn ease 0.3s;
}

.transition-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
</style>