<template>
  <div
      :class="class"
      style="position: relative; display: inline-block;"
      @click="handleClick"
  >
    <img
        ref="img"
        :src="src"
        :alt="alt || 'Image'"
        @load="onImageLoad"
        @error="handleError"
        :class="imgClass"
        style="display: none;"
    />
    <canvas
        ref="canvas"
        :width="computedWidth"
        :height="computedHeight"
        style="position: absolute; top: 0; left: 0; pointer-events: none;"
    ></canvas>
  </div>
</template>

<script>
import { defineComponent, ref, computed, watch } from "vue";

export default defineComponent({
  name: "RlccsImage",
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      default: "",
    },
    imgWidth: {
      type: [Number, String],
      default: 0,
    },
    imgHeight: {
      type: [Number, String],
      default: 0,
    },
    class: {
      type: String,
      default: "",
    },
    imgClass: {
      type: String,
      default: "",
    },
    crossedOut: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:crossedOut"],
  setup(props, { emit }) {
    const img = ref(null); // Reference to the loaded <img> element
    const canvas = ref(null); // Reference to the canvas

    const resolvedWidth = computed(() =>
        typeof props.imgWidth === "number" ? `${props.imgWidth}px` : props.imgWidth
    );

    const resolvedHeight = computed(() =>
        typeof props.imgHeight === "number" ? `${props.imgHeight}px` : props.imgHeight
    );

    const computedWidth = computed(() =>
        typeof props.imgWidth === "number"
            ? props.imgWidth
            : parseFloat(props.imgWidth) || img.value?.naturalWidth || 0
    );

    const computedHeight = computed(() =>
        typeof props.imgHeight === "number"
            ? props.imgHeight
            : parseFloat(props.imgHeight) || img.value?.naturalHeight || 0
    );

    // Function to redraw the image
    const drawImage = (isCrossedOut) => {
      if (!canvas.value || !img.value) return;
      const ctx = canvas.value.getContext("2d");

      // Clear the canvas first
      ctx.clearRect(0, 0, canvas.value.width, canvas.value.height);

      if (isCrossedOut) {
        // Draw the image with 50% transparency
        ctx.globalAlpha = 0.5; // Set transparency
        ctx.drawImage(img.value, 0, 0, canvas.value.width, canvas.value.height);

        ctx.globalAlpha = 1; // Reset transparency to 1 (opaque)

        // Draw the red diagonal cross
        ctx.strokeStyle = "red";
        ctx.lineWidth = 25;
        ctx.beginPath();
        ctx.moveTo(0, 0); // Top-left to bottom-right
        ctx.lineTo(canvas.value.width, canvas.value.height);
        ctx.moveTo(canvas.value.width, 0); // Top-right to bottom-left
        ctx.lineTo(0, canvas.value.height);
        ctx.stroke();
      } else {
        // Draw the image at full opacity
        ctx.globalAlpha = 1;
        ctx.drawImage(img.value, 0, 0, canvas.value.width, canvas.value.height);
      }
    };

    const onImageLoad = () => {
      // Wait for the image to load before setting canvas dimensions and drawing
      if (img.value && canvas.value) {
        canvas.value.width = computedWidth.value || img.value.naturalWidth;
        canvas.value.height = computedHeight.value || img.value.naturalHeight;

        // Redraw the image based on the current `crossedOut` state
        drawImage(props.crossedOut);
      }
    };

    const setCrossedOut = (value) => {
      drawImage(value); // Redraw the canvas with the updated state
      emit("update:crossedOut", value); // Notify parent of state change
    };

    const handleClick = (event) => {
      if (event.button === 0 && event.shiftKey) {
        const newState = !props.crossedOut; // Toggle state
        setCrossedOut(newState); // Update state and emit event
      }
    };

    // Watch crossedOut prop for changes
    watch(
        () => props.crossedOut,
        (newValue) => {
          drawImage(newValue);
        }
    );

    return {
      img,
      canvas,
      resolvedWidth,
      resolvedHeight,
      computedWidth,
      computedHeight,
      setCrossedOut,
      handleClick,
      onImageLoad,
    };
  },
});
</script>

<style scoped>
canvas {
  display: block;
}

img {
  display: none; /* Hide the original image since the canvas renders it */
  user-select: none;
  -webkit-user-drag: none;
}
</style>