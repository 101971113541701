<template>
  <div>
    <div>
      <div class="splash">
        <img class="logo" src="/images/rlccsbv-logo-dark.svg" alt="rlccs logo"/>
      </div>
      <nav>
        <button class="custom-btn" @click="currentView = 'home'">Home</button>
        <button class="custom-btn" @click="currentView = 'projects'">Projects</button>
        <button class="custom-btn" @click="currentView = 'participations'">Participations</button>
        <button class="custom-btn" @click="currentView = 'expertise'">Expertise</button>
        <button class="custom-btn" @click="currentView = 'contact'">Contact</button>
      </nav>
      <HomeVue v-if="currentView === 'home'"/>
      <ProjectVue v-else-if="currentView === 'projects'"/>
      <ParticipationVue v-else-if="currentView === 'participations'"/>
      <ExpertiseVue v-else-if="currentView === 'expertise'"/>
      <ContactVue v-else-if="currentView === 'contact'"/>
    </div>
  </div>

</template>

<script>
import HomeVue from '@/components/HomeVue.vue'
import ParticipationVue from '@/components/ParticipationVue.vue'
import ProjectVue from '@/components/ProjectVue.vue'
import ExpertiseVue from '@/components/ExpertiseVue.vue'
import ContactVue from '@/components/ContactVue.vue'

export default {
  name: 'App',
  components: {
    HomeVue,
    ParticipationVue,
    ProjectVue,
    ExpertiseVue,
    ContactVue
  },
  data() {
    return {
      currentView: 'home',
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Monda, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 10px;
}

.splash {
  border: none;
  background-color: #ddd;
  border-radius: 10px;
}

.logo {
  width: 23rem;
}

.custom-btn {
  border: none;
  background-color: #4498af; /* Example: green background */
  color: white; /* White text */
  padding: 10px 20px; /* Padding for size */
  text-align: center; /* Text alignment */
  text-decoration: none; /* Remove underline */
  display: inline-block; /* Behave like an inline block */
  font-size: 16px; /* Adjust font size */
  cursor: pointer; /* Pointer cursor */
  border-radius: 5px; /* Optional: rounded corners */
  margin: 4px;
}

.custom-btn:hover {
  background-color: #1c5f7a; /* Slightly darker green on hover */
}

</style>
